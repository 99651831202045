import MasterService from './MasterService';

export const IfService = {
    ...MasterService,
    
   
    getIfSchemType(body){ 
        return this.post("inventoryFunding/ifSchemes",body)
    },
    saveIfScheme(body){ 
        return this.post("inventoryFunding/saveIfScheme",body)
    },
    getAllIfSchme(body){ 
        return this.post("inventoryFunding/getAllIf",body)
    },
    updateStatus(body){ 
        return this.post("inventoryFunding/updateIfSchemeStatus",body)
    },
    getIfConfig(body){ 
        return this.get("inventoryFunding/masterConfig",body)
    },
    updateTargetStatus(body){ 
        return this.post("inventoryFunding/updateTargetStatus",body)
    },

   
}
