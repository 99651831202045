import React, { Component } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
//import { Accordion, Card, Button, DropdownButton, Dropdown } from "react-bootstrap";
import Notifaction from './Notification'
import logo_img from "../../webroot/images/logo.svg";
import secureStorage from '../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { Navigate } from "react-router";
import * as HELPER from '../../config/helper';

// const Navigation = () => {

//     this.state = {
//         activeId: null  // nothing selected by default, but this is up to you...
//     }

//     handleClick(event, id) {
//         this.setState({ activeId: id })
//     }

// Helper to inject `navigate` into a class component
function withRouter(Component) {
    return function ComponentWithNavigate(props) {
      const navigate = useNavigate();
      return <Component {...props} navigate={navigate} />;
    };
}

class Navigation extends Component {
    _isMount = false
    constructor(props) {
        super(props);
        this.state = {
            activeId: null,  // nothing selected by default, but this is up to you...
            redirect: false,
            name: "",
            role:""
        }
    }
    componentDidMount = () => {
        this._isMount = true
        let result = secureStorage.getItem("authUserInfo");

        if (!result) {
            this.setState({ redirect: true });
        } else {
            let userInfo = result.user_data;
            this.setState({ name: userInfo.name || 'User', role: userInfo.role });
        }
    }

    handleClick(event, id) {
        this.setState({ activeId: id })
    }
    changeLanguage = (lang) => {
        if (this._isMount) {
            secureStorage.setItem('lang', lang);
            this.props.i18n.changeLanguage(lang)
        }
    }
    logOutUser = async () => {
        let result = secureStorage.getItem("authUserInfo");
        if (result) {
            secureStorage.removeItem("authUserInfo");
            toast.success(`LogOut Successfully`);
            this.setState({ redirect: true });
        }
    };
    checkVisibleMenu = (menus) => {
        return menus.filter(menu => menu.show_in_menu)
    }
    linkUrl = (menu) => {
        let menuLink = '#'
        if (menu['action']) {
            menuLink = '/' + menu['action']
        } 
        return menuLink 
    }
    getCurrentClass = (menuData) => {
        let currentPath = window.location.pathname.split('/');
        let activeClass = '';
        currentPath = currentPath.filter(path => path)  
        if (currentPath.length) {
            let stringMenuData = JSON.stringify(menuData)  
            let checkActiveUrl = HELPER.isUserHasAccessPage(currentPath[0], stringMenuData) 
            if (checkActiveUrl) activeClass = 'active'; 
        } else { 
            if (menuData.name === 'Dashboard') {
                activeClass = 'active';
            }
        }
        return activeClass;
    }
    render() {
        const { name , role } = this.state;
        let language = secureStorage.getItem("lang") === "id" ? "ID" : "EN";
        const authUserInfo = secureStorage.getItem('authUserInfo') || {};

        let isAuthorized = (authUserInfo && authUserInfo['headerMenu']) ? HELPER.isUserHasAccessPage() : true 
        if (this.state.redirect || !isAuthorized) {
            secureStorage.removeItem("authUserInfo");
            // return <Navigate to="/login" />;
            this.props.navigate("/login");
        }
       
        const menuHeaders = authUserInfo && authUserInfo.headerMenu ? JSON.parse(authUserInfo.headerMenu) : [];
        const visibleMenus = this.checkVisibleMenu(menuHeaders);
     
        return (
            <div className="container-fluid" >
                <div className="header-main">
                    <div className="logo">
                        <img src={logo_img} className="" alt='incentive' />
                    </div>
                    <div className="right-panel-naviganion menupanel">
                        <div className="nav">
                            <ul>
                            {
                                        visibleMenus && (visibleMenus).length
                                            ?
                                            (visibleMenus).map((el, i) => {

                                                return (<li className={el.childMenu.length && !el['action'] ? 'dropdownmenu' : ''} key={i}>

                                                    <NavLink to={this.linkUrl(el)} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
                                                        {this.props.t('MENU.' + (el.name).replaceAll(' ','_'))}
                                                    </NavLink>

                                                    <ul className="submenu" key={i}>
                                                        {
                                                            this.checkVisibleMenu(el['childMenu']).map((elm, j) => {
                                                                let childMenuName = elm['name'].replaceAll(' ', '_').toLowerCase()
                                                                return (<li className="level2" key={j}>

                                                                    <NavLink to={this.linkUrl(elm)} className={this.getCurrentClass(elm)}>{this.props.t('MENU.' + childMenuName)}</NavLink>
                                                                    {/* <ul className="submenu" >
                                                                        
                                                                        {
                                                                            
                                                                            this.checkVisibleMenu(elm['childSubMenu']).map((elmn,k)=>{
                                                                                let subMenuName = elmn['name'].replace(' ', '_').toLowerCase()

                                                                                    return (<li key={k}><NavLink to={this.linkUrl(elmn)}>{this.props.t('menu.'+subMenuName)}</NavLink></li>)
                                                                            })
                                                                        }
                                                                    </ul> */}
                                                                </li>)
                                                            })
                                                        }
                                                    </ul>
                                                </li>)

                                            })
                                            :
                                            (
                                                <li></li>
                                            )
                                    }
                                {/* <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                                <li><NavLink to="/incentive">Config Panel </NavLink></li> */}

                            </ul>
                        </div>
                        <a className="notification-btn" href="/#" >
                            <Notifaction />

                        </a>

                        <div className="user-detail ">
                            <ul>

                                <li className="dropdownmenu">
                                    <NavLink to="#">
                                        <span className="userarc">
                                            {name.length && name[0].toUpperCase()}
                                        </span>
                                        <div className="username">
                                            <span>{name}</span>
                                            <span className="user-degintion">{role}</span>
                                        </div>
                                    </NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="#" onClick={this.logOutUser.bind()}>Log Out</NavLink> </li>
                                    </ul>
                                </li>

                            </ul>
                        </div>

                        <div className="language">
                            <ul>
                                <li className="dropdownmenu">
                                    <NavLink to="#">
                                        {language}
                                    </NavLink>
                                    <ul className="submenu lang-menu">
                                        <li><NavLink to="#" onClick={() => this.changeLanguage('en')}>EN</NavLink></li>
                                        <li><NavLink to="#" onClick={() => this.changeLanguage('id')}>ID</NavLink></li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div >
        );
    }
}
export default withTranslation("common")(withRouter(Navigation));