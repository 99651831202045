const CheckValidationFormField = (name, value,element,t) => {
    let disbursalData=JSON.parse(JSON.stringify(element[element.form_config.KEY]));

    let splitted_name = name.split("_");
    let index=splitted_name[splitted_name.length-1];
    let inputFieldName = JSON.parse(JSON.stringify(splitted_name));
    inputFieldName.pop();
    let front_name = inputFieldName.join('_');

    let formIsValid = true;
    let tempErrors = {}; 
    if (element.form_config.FRESH_DISBURSAL_CARD || element.form_config.DEALER_ACTIVATED_CARD || element.form_config.RENEWAL_REPAYMENT_CARD || element.form_config.ACTIVATED_ONBOARD_CARD || element.form_config.AGEING_CLOSED_LOAN || element.form_config.PROFIT_SHARING_1){

        let currentrow=disbursalData[index];
        let disbursalStatus=true;

        if(disbursalData.length>1){
            disbursalStatus=true;
            let Disbdata=delete disbursalData[index];
            if(Disbdata.length){
                disbursalStatus=true;
            }else{
                disbursalStatus=false;
            }
        }else{
            disbursalStatus=false;
        }

        // if(disbursalStatus){
        //     let disbData = disbursalData.filter((el)=>el.min_disb_per!==null && el.max_disb_per!==null);

        //     disbData.sort((a, b) => a["min_disb_per"] - b["min_disb_per"]);

        //     for (let i = 0; i <= disbData.length-1; i++) {

        //         if(front_name==='min_disb_per'  && ((currentrow['min_disb_per']!==null && +value>=currentrow['max_disb_per']) || (value>=disbData[i]['min_disb_per'] && value<=disbData[i]['max_disb_per']))){
        //             formIsValid = false;
        //             tempErrors["min_disb_per_" + index] =t('IF.VALIDATION.DISB_RANGE');
        //             break;
        //         }else if(front_name==='min_disb_per') {
        //             formIsValid = true;
        //             delete tempErrors["min_disb_per_" + index];  
        //         }

        //         if(field==='max_disb_per'  && ((currentrow['min_disb_per']!==null && +value<=currentrow['min_disb_per']) || (value>=disbursal[i]['min_disb_per'] && value<=disbursal[i]['max_disb_per']))){
        //             errorvalue = false;
        //             tempErrors["max_disb_per_" + index] = t('IF.VALIDATION.DISB_RANGE');
        //             break;
        //         }else if(field==='max_disb_per') {
        //             formIsValid = true;
        //             delete tempErrors["max_disb_per_" + index];  
        //         }
        //     }


        // }else{
        //     if(front_name==='min_disb_per' && disbursalData[index]['max_disb_per']!==null && (+value>=disbursalData[index]['max_disb_per'])){
        //         formIsValid = false;
        //         tempErrors["min_disb_per_" + index] = t('IF.VALIDATION.DISB_RANGE');
        //     }else if(front_name==='min_disb_per') {
        //         formIsValid = true;
        //         delete tempErrors["min_disb_per_" + index]; 
        //     }

        //     if(front_name==='max_disb_per' && disbursalData[index]['min_disb_per']!==null && (+value<=disbursalData[index]['max_disb_per'])){
        //         formIsValid = false;
        //         tempErrors["max_disb_per_" + index] = t('IF.VALIDATION.DISB_RANGE');
        //     }else if(front_name==='max_disb_per') {
        //         formIsValid = true;
        //         delete tempErrors["max_disb_per_" + index]; 
        //     }
        // }
    }

    return {tempErrors, formIsValid};

}

export default CheckValidationFormField;