import secureStorage from "./encrypt";
import _ from "lodash";
export const API_URL = {
  GETWAY_API: process.env.REACT_APP_GETWAY_API,
  COMMON_GATEWAY_API: "https://int-gcloud-stage.oto.com/",
  API_KEY: "agentbox-ac1faa7b-9fe9-4483-9525-5cc4ce94c639"
};

export const SETTING = {
  CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
  RESEND_OTP_TIME_IN_SECOND: 30,
};
export const NAME_VALIDATION = /^[a-zA-Z ]+$/;

export const ALPHA_NUMERIC_VALIDATION = /^[a-zA-Z0-9 ]+$/;

export const ACCOUNT_NO_VALIDATION = /^\d{10,16}$/;

export const SPOUSE_OFFICE_ADDRESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,70}$/;

export const SPOUSE_BUSINESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,20}$/;

export const EMERGENCY_CONTACT_NAME_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,50}$/;

export const EMERGENCY_CONTACT_ADDRESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,70}$/;

export const MOBILE_VLIDATION_RULE = /^[8]\d{8,11}$/;

export const MARGIN_FOR_OTO_MAX_VALIDATION = 1000000;

export const IS_USER_ACCESS_PAGE = (controller, action) => {
	let isUserHasRights = false, isEditAccess = false;
	const userAccessFeatures = secureStorage.getItem("userAccess");
	if (userAccessFeatures && userAccessFeatures.length) {
		let isKeyExist = _.findKey(userAccessFeatures, function (v) {
			if (controller && v.controller && action && v.action) {
				return (
					v.controller.toLowerCase() === controller.toLowerCase() && 
					v.action.toLowerCase() === action.toLowerCase()
				);
			}
		});
		isUserHasRights = isKeyExist ? true : false;
		isEditAccess = isKeyExist ? ((Number(userAccessFeatures[isKeyExist].access_type) === 1) ? true : false) : false
	}

	return {
		is_access_page: isUserHasRights,
		is_edit_access: isEditAccess
	};
};

export const APIV = 2;

export const FINANCIER_PRODUCT_TYPE_OPTIONS = [
	{ label: "UCF", value: "UCF" },
	{ label: "UCRF", value: "UCRF" },
	{ label: "UBRF", value: "UBRF" },
	{ label: "INSURANCE", value: "INSURANCE" },
];

export const STATUS_LIST = {
	pending:0,
	approved: 1,
	rejected:2,
	paid:3,
	on_hold:4 
}; 

export const INCENTIVE_IMAGE_FOLDER="incentive_docs";
export const SFA_IMAGE_FOLDER="sfa_docs";

export const AGENT_ROLE_ID = 53; 
export const ARO_ROLE_ID   = 57; 
export const BRO_ROLE_ID   = 54;
export const ARCO_ROLE_ID  = 59;
export const ZM_ROLE_ID  = 58;
export const BM_ROLE_ID  = 52;
export const AM_ROLE_ID  = 56;
export const RBH_ROLE_ID  = 51;

export const BRO_ALLOWANCE  = 11;
export const ARO_ALLOWANCE  = 18;
export const ARCO_ALLOWANCE  = 27; 

export const ROLE_LABEL_ID = {
    'rbh':'51', 
    'bm':'52', 
    'agent':'53',
    'bro':'54',
    'am':'56', 
    'aro':'57',
    'zm':'58', 
    'arco':'59'
}; 

export const ARO_USER_ROLE = 2;
export const BRO_USER_ROLE = 3;

export const AREA_SIZE  = 1000; 
export const INCENTIVE_FILE_TYPES = ["incentive", "bpjs", "ppd"];
export const INCENTIVE_FILES_OBJ =  { "incentive" : 1, "bpjs" : 2, "ppd" : 3};

export const REDIRECT_URLS = {
	"beta" : "http://sso-beta.oto.com/google?redirect=",
	// "pre-production" : "https://pre-sso.oto.com/google?redirect=",
	"pre-production" : "https://sso.oto.com/google?redirect=",
	"local" : "http://sso-beta.oto.com/google?redirect="
}

export const REDIRECT_URL = REDIRECT_URLS[process.env.REACT_APP_ENV]


export const ALLOWANCE_ARRAY  = [11,18,38,39]; 

export const IF_CARD  = {
	"disbursal_card":{min_disb_per: "",max_disb_per: "",incentive_amount: ""},
	"onboard_card":{operator: "",onboard_limit: "",incentive_amount: ""},
	"addition_incentive_card":{target_amount: "",if_date: "",incentive_amount: ""},
	"penalty_card" : {target_amount : ""},
	"colldoc_card" : {min_onboarded : "", min_activation : "", min_colldoc : "", incentive_amount : ""}
}; 